import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'vue-cookies'
import store from '../store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'root',
            component: () => import(/* webpackChunkName: "Welcome" */ '@/views/Welcome.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/signin',
            name: 'signin',
            component: () => import(/* webpackChunkName: "signIn" */ '@/views/SignIn.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import(/* webpackChunkName: "signUp" */ '@/views/SignUp.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/start/:id',
            name: 'start',
            component: () => import(/* webpackChunkName: "signUp" */ '@/views/GetStarted.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/ResetPassword.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/invitations/:id',
            name: 'invitation',
            component: () => import(/* webpackChunkName: "invitation" */ '@/views/Invitation.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/home',
            name: 'home',
            component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/organization',
            name: 'organization',
            component: () => import(/* webpackChunkName: "Organization" */ '@/views/Organization.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bot',
            name: 'bot',
            component: () => import(/* webpackChunkName: "Organization" */ '@/views/Bot.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/favorites',
            name: 'favorites',
            component: () => import(/* webpackChunkName: "Favorites" */ '@/views/Favorites.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/recent',
            name: 'recent',
            component: () => import(/* webpackChunkName: "recent" */ '@/views/Recent.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/network',
            name: 'network',
            component: () => import(/* webpackChunkName: "network" */ '@/views/MyNetwork.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/inbox',
            name: 'inbox',
            component: () => import(/* webpackChunkName: "inbox" */ '@/views/Inbox.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/network/:id/view',
            name: 'NetworkMemberView',
            component: () => import(/* webpackChunkName: "NetworkMemberView" */ '@/views/members/MemberView.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/vendors',
            name: 'software-vendors-list',
            component: () => import(/* webpackChunkName: "software-vendor-list" */ '@/views/vendors/VendorList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/vendors/:id/view',
            name: 'software-vendors-view',
            component: () => import(/* webpackChunkName: "software-vendors-view" */ '@/views/vendors/VendorView.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/vendors/onboard',
            name: 'software-vendors-onboard',
            component: () => import(/* webpackChunkName: "software-vendors-onboard" */ '@/views/vendors/VendorOnboard.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/partners/onboard',
            name: 'partners-onboard',
            component: () => import(/* webpackChunkName: "partners-onboard" */ '@/views/partners/PartnerOnboard.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/partners',
            name: 'partners-list',
            component: () => import(/* webpackChunkName: "partners-list" */ '@/views/partners/PartnerList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/partners/:id/list',
            name: 'partners-members-list',
            component: () => import(/* webpackChunkName: "partners-members-list" */ '@/views/partners/PartnerMemberList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/partners/:id/view',
            name: 'partners-view',
            component: () => import(/* webpackChunkName: "partners-view" */ '@/views/partners/PartnerView.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/partners/:id/members/:memberId/view',
            name: 'partners-members-view',
            component: () => import(/* webpackChunkName: "partners-members-view" */ '@/views/partners/PartnerMemberView.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/partners/:organizationId/projects',
            name: 'partners-projects-list',
            component: () => import(/* webpackChunkName: "partners-projects-list" */ '@/views/projects/ProjectList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/partners/:organizationId/proposals',
            name: 'partners-proposals-list',
            component: () => import(/* webpackChunkName: "partners-proposals-list" */ '@/views/proposals/ProposalList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customers',
            name: 'customers-list',
            component: () => import(/* webpackChunkName: "customers-list" */ '@/views/customers/CustomerList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/customers/:id/view',
            name: 'customers-view',
            component: () => import(/* webpackChunkName: "customers-view" */ '@/views/customers/CustomerView.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/customers/onboard',
            name: 'customers-onboard',
            component: () => import(/* webpackChunkName: "customers-onboard" */ '@/views/customers/CustomerOnboard.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/customers/:id/list',
            name: 'customers-members-list',
            component: () => import(/* webpackChunkName: "partners-members-list" */ '@/views/customers/CustomerMemberList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/team',
            name: 'MemberList',
            component: () => import(/* webpackChunkName: "MemberList" */ '@/views/members/MemberList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/proposals/:organizationId?',
            name: 'ProposalList',
            component: () => import(/* webpackChunkName: "ProposalList" */ '@/views/proposals/ProposalList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/proposals/:id/view',
            name: 'ProposalView',
            component: () => import(/* webpackChunkName: "ProposalView" */ '@/views/proposals/ProposalView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/solicitations/:id/view',
            name: 'Solicitation',
            component: () => import(/* webpackChunkName: "Solicitation" */ '@/views/proposals/Solicitation.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/talent/:id/view',
            name: 'CandidateDetails',
            component: () => import(/* webpackChunkName: "CandidateDetails" */ '@/views/talent/CandidateDetails.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/members/import',
            name: 'MemberImport',
            component: () => import(/* webpackChunkName: "MemberImport" */ '@/views/members/MemberImport.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/members/create',
            name: 'MemberCreate',
            component: () => import(/* webpackChunkName: "MemberCreate" */ '@/views/members/MemberCreate.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile/certifications',
            name: 'MemberCertifications',
            component: () => import(/* webpackChunkName: "MemberCertifications" */ '@/views/members/MemberCertifications.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile/references',
            name: 'MemberReferences',
            component: () => import(/* webpackChunkName: "MemberReferences" */ '@/views/members/MemberReferences.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/team/:id/view',
            name: 'MemberView',
            component: () => import(/* webpackChunkName: "MemberView" */ '@/views/team/MemberView.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/members/:id/picture',
            name: 'upload-picture',
            component: () => import(/* webpackChunkName: "software-vendors-view" */ '@/views/members/UploadPicture.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/members/:id/edit/:component',
            name: 'MemberEdit',
            component: () => import(/* webpackChunkName: "MemberEdit" */ '@/views/members/MemberEdit.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile',
            name: 'member',
            component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/Profile.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile/photo',
            name: 'uploadPhoto',
            component: () => import(/* webpackChunkName: "UploadPhoto" */ '@/views/profile/UploadPhoto.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/projects',
            name: 'ProjectList',
            component: () => import(/* webpackChunkName: "ProjectList" */ '@/views/projects/ProjectList.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/projects/new',
            name: 'ProjectNew',
            component: () => import(/* webpackChunkName: "ProjectCreate" */ '@/views/projects/ProjectCreate.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/projects/create',
            name: 'ProjectCreate',
            component: () => import(/* webpackChunkName: "ProjectCreate" */ '@/views/projects/ProjectCreate.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/projects/:id/setup',
            name: 'ProjectSteps',
            component: () => import(/* webpackChunkName: "ProjectSetup" */ '@/views/projects/ProjectSetup.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/projects/:id/view',
            name: 'ProjectView',
            component: () => import(/* webpackChunkName: "ProjectView" */ '@/views/projects/ProjectView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/projects/roles',
            name: 'OpenRoles',
            component: () => import(/* webpackChunkName: "ProjectView" */ '@/views/projects/OpenRoles.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/projects/:id/edit/:component',
            name: 'ProjectEdit',
            component: () => import(/* webpackChunkName: "ProjectEdit" */ '@/views/projects/ProjectEdit.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/work',
            name: 'ProjectSearch',
            component: () => import(/* webpackChunkName: "ProjectSearch" */ '@/views/projects/ProjectSearch.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/invitations',
            name: 'InvitationList',
            component: () => import(/* webpackChunkName: "InvitationList" */ '@/views/InvitationList.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/talent',
            component: () => import(/* webpackChunkName: "MemberSearch" */ '@/views/members/MemberSearch.vue'),
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms.vue'),
            meta: {
                requiresAuth: true,
                hideNavbar: true
            }
        },
        {
            path: '/linkedin',
            name: 'LinkedIn',
            component: () => import(/* webpackChunkName: "InvitationList" */ '@/views/members/LinkedIn.vue'),
            meta: {
                requiresAuth: true,
                hideNavbar: true,
                hideAppbar: true
            }
        },
        {
            path: '/not-authorized',
            name: 'not-authorized',
            component: () => import(/* webpackChunkName: "NotAuthorized" */ '@/views/errors/403.vue'),
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/not-found',
            name: 'not-found',
            component: () => import(/* webpackChunkName: "NotFound" */ '@/views/errors/404.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/error',
            name: 'error',
            component: () => import(/* webpackChunkName: "Error" */ '@/views/errors/500.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '*',
            redirect: '/not-found',
            meta: {
                requiresAuth: false
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    store.commit('setLoading', from.path != '/signin');
    store.dispatch('sendPageView', { fullPath: to.path });
    if (to.meta.requiresAuth) {
        if (Cookies.isKey('token')) {
            store.commit("setToken", Cookies.get('token'));
            Promise.all([
                store.dispatch('fetchMetadata'),
                store.dispatch('fetchUserInfo'),
                store.dispatch('fetchNotifications'),
                store.dispatch('fetchInvitations')
            ]).then(() => {
                store.commit("setAuthorized", store.state.userInfo.terms_accepted);
                if (!store.state.userInfo.terms_accepted && to.path != "/terms") {
                    router.replace('/terms');
                } else {
                    next();
                }
            }).catch((error) => {
                if (401 == error.response.status) {
                    if (to.path != null && to.path != "/sigin") {
                        router.replace('/signin?redirect=' + to.path);
                    } else {
                        router.replace('/signin');
                    }
                } else if (403 === error.response.status && to.path != "/not-authorized") {
                    router.replace('/not-authorized').catch(() => { });
                    return false;
                } else if (404 === error.response.status && to.path != "/not-found") {
                    router.replace('/not-found').catch(() => { });
                } else if (500 === error.response.status || 503 === error.response.status) {
                    router.replace('/error').catch(() => { });
                } else {
                    next();
                }
            })
        } else {
            store.commit('resetStore');
            router.replace('/signin?redirect=' + to.path);
        }
    } else {
        next();
    }
})

router.afterEach(() => {
    store.commit('setLoading', false);
})

export default router;